@font-face {
    font-family: 'roboto_mono';
    src: url('../../static/fonts/roboto_mono.ttf') format('truetype');
    font-weight: bold;
    font-style: normal
}

.pricePortal {
    margin: auto;
    max-width: 65%;
    height: 35em;

    &__chart {
        background-color: #1e1e1e !important;
        font-family: roboto_mono;
        font-style: bold;
        color: white;
        margin-bottom: 15px;
    }

    &__stats {
        background-color: #1e1e1e !important;
        font-family: roboto_mono;
        font-style: bold;
        margin-top: 5px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        &-item {
            color: white;
            margin: 10px;
            border: 1px solid #7dcbeb;
            padding: 10px;

            h2 {
                font-family: brrr;
            }

        }
    }
}