@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@font-face {
  font-family: 'brrr';
  src: url('./static/fonts/brrr.otf') format('opentype');
  font-weight: bold;
  font-style: normal
}

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 140vh;
  font-family: brrr;
  font-style: normal;
  background: rgb(51,51,51);
  background: linear-gradient(90deg, rgba(51,51,51,1) 0%, rgba(65,65,65,1) 100%);

  &__header {
    height: 10%;
    width: 100%;
    border-radius: 2px;
    color: white;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 65%;
    width: 100%;

    &-home {
      text-align: center;

      &__box {
        margin-top: 100px;
        font-family: roboto_mono;

        &-buttons {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
        }
      }

    }

    &-docs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      width: 100%;
      color: white;

      &__item {
        margin: 10px;
        border: 1px solid #7dcbeb;

        code {
          font-family: Consolas,"courier new";
          color: crimson;
          padding: 2px;
          font-size: 130%;
        }
      }
    }

    &-dexs {
      max-width: 100%;
      max-height: 50%;
    }
  }

  // &__footer {
  //   height: 10%;
  // }
}